<template>
  <div>
    <!-- 导航栏 -->
    <NavBar />
    <!-- Banner -->
    <Banner
      :logo="require('@/assets/iwall/组 1@2x.png')"
      :mobile-banner="require('@/assets/iwall/banner-mobile.png')"
      info="采用全新的安全基因靶向防护理念 <br /> 向Web应用交互薄弱环节嵌入针对性的安全基因 <br /> 提升Web应用防火墙在高强度攻防对抗中的应对能力"
      btn-style="background: linear-gradient(90deg, #FD964F 0%, #FC6711 100%);"
      :right-img="require('@/assets/iwall/Group 18921@2x.png')">
      <div class="banner__tips">
      </div>
    </Banner>
    <div class="first">
      <Item
        title="传统WAF防护效果为何不好？"
        title-color="color: #FF7E15;"
        info="近年来，随着企业级信息安全体系建设的日趋完善，以SQL注入攻击、XSS攻击等为代表的传统应用层攻击所带来的威胁已经得到很大程度的缓解。但是，自动化攻击、0day攻击和业务层攻击等新一代攻击手段大幅提升了Web应用层攻防对抗的强度，而传统WAF对此普遍缺乏应对能力。">
        <div class="item__case case1">
          <video src="http://124.222.14.165/videos/waf01.mp4" controls></video>
        </div>
      </Item>
    </div>
    <Item
      title="安全基因靶向防护理念技术实践"
      title-color="color: #FF7E15;"
      info="安全基因靶向防护理念的核心思想是变被动防御为主动防御，通过将具有针对性的安全基因嵌入到Web应用交互过程中的薄弱环节，提升Web应用自身的健壮性。实现“阻探测、锁来源、免暴露、清异常”。">
      <div class="item__case case2">
        <div class="pageing__header">
          <div
            v-for="(item, index) in list"
            class="paging"
            :class="{
              active: index === activeIndex
            }"
            @click="handleSwiperSlideTo(index)"
            :key="item.name">
            {{ item.name }}
          </div>
        </div>
        <div class="swiper__main">
          <Swiper
            ref="mySwiper"
            :options="swiperOptions">
            <SwiperSlide v-for="item in list" :key="item.name">
              <div class="swiper__main__item">
                <div class="swiper__main__header">
                  <div class="swiper__main__title">{{ item.name }}</div>
                  <div class="swiper__main__info">{{ item.info }}</div>
                </div>
                <div class="swiper__main__img">
                  <img :src="item.img" alt="">
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </Item>
    <Item
      title="产品特色"
      title-color="color: #FF7E15;">
      <Media>
        <div class="item__case case3" style="padding: 0 175px">
          <div class="case3__item">
            <img src="@/assets/iwall/case3-item1.png" alt="">
            <img src="@/assets/iwall/case3-item1-hover.png" alt="">
          </div>
          <div class="case3__item">
            <img src="@/assets/iwall/case3-item2.png" alt="">
            <img src="@/assets/iwall/case3-item2-hover.png" alt="">
          </div>
          <div class="case3__item">
            <img src="@/assets/iwall/case3-item3.png" alt="">
            <img src="@/assets/iwall/case3-item3-hover.png" alt="">
          </div>
          <div class="case3__item" style="margin-top: 30px">
            <img src="@/assets/iwall/case3-item4.png" alt="">
            <img src="@/assets/iwall/case3-item4-hover.png" alt="">
          </div>
          <div class="case3__item" style="margin-top: 30px">
            <img src="@/assets/iwall/case3-item5.png" alt="">
            <img src="@/assets/iwall/case3-item5-hover.png" alt="">
          </div>
          <div class="case3__item" style="margin-top: 30px">
            <img src="@/assets/iwall/case3-item6.png" alt="">
            <img src="@/assets/iwall/case3-item6-hover.png" alt="">
          </div>
        </div>
        <template v-slot:mobile>
          <div class="case3-mobile">
            <div class="case3-mobile__item">
              <img
                src="@/assets/iwall/case3-mobile-item1.png"
                alt=""
                class="img">
              <div class="title">OWASP TOP 10防御</div>
              <div class="info">异常报文检测、通用应用漏洞攻击检测、服务器脚本执行检测、特定应用漏洞攻击检测</div>
            </div>
            <div class="case3-mobile__item">
              <img
                src="@/assets/iwall/case3-mobile-item2.png"
                alt=""
                class="img">
              <div class="title">机器人防御</div>
              <div class="info">Bots识别：五个维度精准识别机器人 <br />Bots防御：通过指纹提取、频度控制、访问追踪等多种技术手段缓解撞库、薅羊毛、暴力破解、验证码绕过、爬虫、秒杀、刷票等各种自动化攻击</div>
            </div>
            <div class="case3-mobile__item">
              <img
                src="@/assets/iwall/case3-mobile-item3.png"
                alt=""
                class="img">
              <div class="title">Web应用层0day攻击防御</div>
              <div class="info">通过减小Web应用暴露面并阻断漏洞挖掘/探测行为让0Day攻击的EXP丧失目标；<br />通过访问实体多维画像锁定异常访问源，并通过访问追踪捕获异常访问行为，让0Day攻击无处遁形，实现无规则防御。</div>
            </div>
            <div class="case3-mobile__item">
              <img
                src="@/assets/iwall/case3-mobile-item4.png"
                alt=""
                class="img">
              <div class="title">API攻击防御</div>
              <div class="info">开机即可对API参数污染、API接口滥用提供有效的防御措施；<br />通过可编程对抗引擎，可结合防护对象的具体特性实现对API逻辑攻击的全面防御，例如：API乱序调用、API越权访问等。</div>
            </div>
            <div class="case3-mobile__item">
              <img
                src="@/assets/iwall/case3-mobile-item5.png"
                alt=""
                class="img">
              <div class="title">响应数据脱敏</div>
              <div class="info">可以基于各类数据模板对HTTP的响应数据进行脱敏处理，例如手机号、身份证号、明文密码、电子邮箱、居住地址等。</div>
            </div>
            <div class="case3-mobile__item">
              <img
                src="@/assets/iwall/case3-mobile-item6.png"
                alt=""
                class="img">
              <div class="title">业务逻辑漏洞在线修复</div>
              <div class="info">通过可编程对抗引擎可实现对业务流程绕行、关键数据篡改、竞争条件漏洞等业务逻辑漏洞的在线修复。</div>
            </div>
          </div>
        </template>
      </Media>
    </Item>
    <Item
      class="page-last"
      title="iWall WAF3.0部署"
      title-color="color: #FF7E15;"
      info="iWall-WAF3.0 为独立硬件设备，采用代理工作机制：">
      <media>
        <div class="item__case case4">
          <img src="@/assets/iwall/Group 23134@2x.png" alt="">
        </div>
        <template v-slot:mobile>
          <div class="item__case case4">
            <img src="@/assets/iwall/Group 23134@2xmob.png" alt="">
          </div>
        </template>
      </media>
    </Item>
    <!-- 底部 -->
    <Footer />
  </div>
</template>>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import Banner from '../components/banner'
import Item from '../components/item'

import SwiperBanner1 from '@/assets/iwall/组 55809@2x.png'
import SwiperBanner2 from '@/assets/iwall/组 55808@2x.png'
import SwiperBanner3 from '@/assets/iwall/组 55807@2x.png'
import SwiperBanner4 from '@/assets/iwall/组 55806@2x.png'

export default {
  components: {
    Swiper,
    SwiperSlide,
    Banner,
    Item
  },
  data () {
    return {
      list: [
        {
          name: '阻探测',
          info: 'iWall-WAF3.0从访问行为、访问数据、访问设备三个维度上全方位治理自动化探测。此外，还通过防数据重放、浏览器调试跟踪、Cookie加密和防篡改、HTML代码混淆、URI混淆等保护措施禁止手工探测。',
          img: SwiperBanner1
        },
        {
          name: '锁来源',
          info: 'iWall-WAF3.0通过在访问设备特征、浏览器特征、访问行为、访问终端运行环境、IP地址等多个维度上设置监测卡点，精准锁定攻击者。',
          img: SwiperBanner2
        },
        {
          name: '免暴露',
          info: 'iWall-WAF3.0采用影子防御技术来实现应用指纹模糊化、访问路径监控和关键数据混淆，从而最大程度地缩小Web应用的暴露面，让攻击者无论是分析还是攻击都无从下手。',
          img: SwiperBanner3
        },
        {
          name: '清异常',
          info: '在实时阻断异常的访问行为的同时，iWall-WAF3.0将访问终端标记为异常访问实体记录在信誉库中，并在其后续的访问中对其采取相应的处置措施。',
          img: SwiperBanner4
        }
      ],
      activeIndex: 0,
      swiperOptions: {
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.initialSwiperEvents()
  },
  methods: {
    initialSwiperEvents () {
      this.$nextTick(() => {
        this.swiper.on('slideChange', this.slideChange)
      })
    },
    slideChange () {
      this.activeIndex = this.swiper.activeIndex
    },
    handleSwiperSlideTo (index) {
      this.activeIndex = index
      this.swiper.slideTo(index, 500, false)
    }
  }
}
</script>
<style lang="scss" scoped>
.banner__tips {
  @media screen and (max-width: 750px) {
    width: 100%;
    border: none;
    border-radius: px2rem(60);
    background: rgba(255, 255, 255, 1);
    padding: px2rem(100) px2rem(50) 0;
    margin-top: -40px;
    box-shadow: 0px -6px 6px 0px rgba(0, 0, 0, 0.1);
  }
}
.case3-mobile__item {
  padding: px2rem(100) 100px;

  &:nth-of-type(n+2) {
    margin-top: 150px;
  }

  .img {
    width: 100%;
  }
  .title {
    font-size: 52px;
    font-weight: bold;
    color: #FC6813;
    text-align: center;
    margin-top: 63px;
  }
  .info {
    font-size: 45px;
    font-weight: 400;
    color: #666666;
    line-height: 80px;
    margin-top: 63px;

    @media screen and (max-width: 750px) {
      padding: 0 px2rem(50) 0;
      text-align: justify;
    }
  }
}
.item__case {
  &.case1 {
    width: 74%;
    margin: auto;

    video {
      width: 100%;
    }

    @media screen and (max-width: 750px) {
      width: 100% !important;
    }
  }
  &.case2 {
    width: 74%;
    margin: auto;
    height: 411px;
    border-radius: 12px 12px 12px 12px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);

    @media screen and (max-width: 750px) {
      width: 100% !important;
      height: 690px;
    }

    .pageing__header {
      display: flex;
      align-items: center;
      padding: 0 85px;
      height: 118px;
      border-radius: 12px 12px, 0px, 0px;
      background: rgba(248, 248, 248, 1);
      box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.1);
    }

    .paging {
      flex: 1;
      text-align: center;
      border-bottom: 2PX solid rgba(204, 204, 204, 1);
      font-size: px2rem(24);
      font-weight: 600;
      padding-bottom: 4px;
      line-height: 1.6;
      cursor: pointer;
      color: rgba(255, 126, 21, 1);

      @media screen and (max-width: 750px) {
        font-size: px2rem(42) !important;
      }

      &.active {
        border-bottom: 4px solid rgba(255, 126, 21, 1);
      }
    }

    .swiper__main__item {
      display: flex;
      padding: 56px;
      .swiper__main__title {
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 24px;
        color: rgba(255, 126, 21, 1);

        @media screen and (max-width: 750px) {
          font-size: px2rem(42) !important;
          padding-left: px2rem(45) !important;
        }
      }
      .swiper__main__info {
        font-family: Source Han Sans CN;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 1.8;
        text-align: justify;
        margin-right: 12%;
        color: rgba(102, 102, 102, 1);

        @media screen and (max-width: 750px) {
          font-size: px2rem(36) !important;
          padding-left: px2rem(45) !important;
          margin-right: 8%;
        }
      }
    }

    .swiper__main__img {
      img {
        width: 227px;
        height: 220px;

        @media screen and (max-width: 750px) {
          width: px2rem(382);
          height: px2rem(375);
        }
      }
    }
  }

  &.case3 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      flex-wrap: nowrap;

      img {
        width: 100% !important;
      }
    }

    .case3__item {
      position: relative;
      width: 301px;
      background: #FAFAFA;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 12px;
      overflow: hidden;

      &:hover {
        img:nth-of-type(2) {
          top: 0;
        }
      }

      &:nth-of-type(n+4) {
        margin-top: 60px;
      }

      img {
        width: 100%;
        height: 100%;
        &:nth-of-type(2) {
          position: absolute;
          left: 0;
          top: 100%;
          transition: all 0.5s ease-in;
        }
      }
    }
  }

  &.case4 {
    width: 74%;
    margin: auto;
    font-size: 0;
    img {
      width: 100%;
    }

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }
}
.showcase__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  img {
    width: 401px;
    margin: 14px;
  }
}
</style>
